import Cookies from 'js-cookie';

export class HeaderBar {
    constructor() {
        this.cookieAdvertisingEnabled = Cookies.get('cookies-allowed-advertising');
        this.$headerBar = null;
    }

    init() {
        this.$headerBar = document.querySelector('.header-bar');
        this.togglePromoBar();
        this.addListeners();
    }

    addListeners() {
        let jsCloseBar = document.querySelector('.js-close-bar');
        if (jsCloseBar !== null) {
            jsCloseBar.addEventListener('click', function () {
                let $container = jsCloseBar.parentElement;
                $container.setAttribute('hidden', 'hidden');
                Cookies.set(
                    $container.getAttribute('data-cookie'),
                    $container.getAttribute('data-cookie-id'),
                    {expires: 30}
                );
            });
        }
    }

    togglePromoBar() {
        if (this.$headerBar === null) {
            return;
        }

        if (this.$headerBar.getAttribute('data-cookie-id') === Cookies.get('hb-promo')) {
            this.$headerBar.setAttribute('hidden', 'hidden');
        } else {
            this.$headerBar.removeAttribute('hidden');
        }
    }
}

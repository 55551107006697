import {elementShow, elementHide} from './features';

export class HeaderMobileMenu {
    constructor() {
        this.$navigationSectionsList = null;
        this.$navigationHeadlinesList = null;
        this.$navMenu = null;
        this.$mobileSearch = null;
        this.$headerLogo = null;
        this.$headerCart = null;
    }

    init() {
        this.$navigationSectionsList = document.querySelectorAll('.c-mobile-menu-nav__section');
        this.$navigationHeadlinesList = document.querySelectorAll('.c-mobile-menu-nav__section-label');
        this.$navMenu = document.querySelector('.js-mobile-nav-menu');
        this.$mobileSearch = document.querySelector(".c-header__mobile-search");
        this.$headerLogo = document.querySelector(".c-header__logo");
        this.$headerCart = document.querySelector(".c-header__cart-box");
        this.$promobars = document.querySelector(".c-header-promo-bar");
        this.addListeners();
    }

    addListeners() {
        let _this = this;

        if (_this.$navMenu !== null) {
            _this.$navMenu.addEventListener('click', function () {
                document.querySelector("html").classList.toggle("overflow-hidden");
                document.querySelector("body").classList.toggle("overflow-hidden");

                if (_this.$promobars !== null) {
                    document.querySelector(".c-header-promo-bar").classList.toggle("hidden");
                }
                if (_this.$mobileSearch !== null) {
                    if (_this.$mobileSearch.getAttribute('hidden')) {
                        _this.$mobileSearch.removeAttribute('hidden')
                    } else {
                        _this.$mobileSearch.setAttribute('hidden', 'hidden');
                    }
                }

                if (_this.$headerLogo !== null) {
                    if (_this.$headerLogo.getAttribute('hidden')) {
                        _this.$headerLogo.removeAttribute('hidden')
                    } else {
                        _this.$headerLogo.setAttribute('hidden', 'hidden');
                    }
                }

                if (_this.$headerCart !== null) {
                    if (_this.$headerCart.getAttribute('hidden')) {
                        _this.$headerCart.removeAttribute('hidden')
                    } else {
                        _this.$headerCart.setAttribute('hidden', 'hidden');
                    }
                }
            });
        }

        [..._this.$navigationHeadlinesList].forEach(headlineElement => headlineElement.addEventListener('click', function (e) {
            let section = headlineElement.closest('.c-mobile-menu-nav__section');
            let isOpened = section.getAttribute('data-opened');

            // close all opened sub-menus
            _this.closeAllNavigationMenus();

            // open clicked menu (only if it was closed)
            if (isOpened !== 'yes') {
                section.setAttribute('data-opened', 'yes');
                headlineElement.classList.toggle('open', true);
                elementShow(section.querySelector('.c-mobile-menu-nav__section-nav'));
            }
        }));
    }

    closeAllNavigationMenus()
    {
        // remove all sections status "open"
        [...this.$navigationSectionsList].forEach(sectionElement => {
            sectionElement.setAttribute('data-opened', 'no');

            let headline = sectionElement.querySelector('.c-mobile-menu-nav__section-label');
            if (headline !== null) {
                headline.classList.toggle('open', false);
            }

            let submenu = sectionElement.querySelector('.c-mobile-menu-nav__section-nav');
            if (submenu !== null) {
                elementHide(submenu);
            }
        });
    }
}

/**
 * @param {Element} element
 * @param {Boolean} skipUpdateStyles
 */
export function elementShow(element, skipUpdateStyles = false) {
	if (element === null) {
		return;
	}
	if (skipUpdateStyles === false) {
		element.style.display = "block";
	}
	elementClassRemove(element, "hidden");
}

/**
 * @param {Element} element
 * @param {Boolean} skipUpdateStyles
 */
export function elementHide(element, skipUpdateStyles = false) {
	if (element === null) {
		return;
	}

	if (skipUpdateStyles === false) {
		element.style.display = "block";
		element.style.display = "none";
	}
	elementClassAdd(element, "hidden");
}

/**
 * @param {Element} element
 * @param {String} className
 */
export function elementClassAdd(element, className) {
	if (element === null) {
		return;
	}
	element.classList.toggle(className, true);
}

/**
 * @param {Element} element
 * @param {String} className
 */
export function elementClassRemove(element, className) {
	if (element === null) {
		return;
	}
	element.classList.toggle(className, false);
}

/**
 * @param {Element} element
 * @param {String} className
 * @return {Boolean}
 */
export function elementHasClass(element, className) {
	if (element === null) {
		throw "Element can not be null.";
	}
	return element.classList.contains(className);
}

/**
 * @param {Element} element
 * @param {Boolean} force
 */
export function elementToggle(element, force) {
	if (element === null) {
		return;
	}

	if (force === true) {
		// forced state - show
		elementShow(element);
	} else if (force === false) {
		// forced state - hide
		elementHide(element);
	} else {
		// do by actual state
		if (element.style.display === "none") {
			elementShow(element);
		} else {
			elementHide(element);
		}
	}
}

/**
 * @param {String} selector
 * @param {Element} $container
 * @returns {Element|null}
 */
export function getSelectSelectedOptionBySelector(selector, $container) {
	let options;
	if ($container !== undefined) {
		options = $container.querySelector(selector);
	} else {
		options = document.querySelector(selector);
	}

	if (options) {
		for (let i = 0; i < options.length; i++) {
			if (options[i].selected) {
				return options[i];
			}
		}
	}

	return null;
}

/**
 * @param {Element} element
 * @returns {Element|null}
 */
export function getSelectSelectedOptionByElement(element) {
	let options = element.options;
	if (options) {
		for (let i = 0; i < options.length; i++) {
			if (options[i].selected) {
				return options[i];
			}
		}
	}

	return null;
}

/**
 * @param {String} selector
 * @param {Element} $container
 * @returns {Element|null}
 */
export function getInputCheckedOptionBySelector(selector, $container) {
	let inputsElements;
	if ($container !== undefined) {
		inputsElements = $container.querySelectorAll(selector);
	} else {
		inputsElements = document.querySelectorAll(selector);
	}

	return getInputSelectedOptionByElement(inputsElements);
}

/**
 * @param {NodeListOf<Element>} inputsElements
 * @returns {Element|null}
 */
export function getInputSelectedOptionByElement(inputsElements) {
	if (inputsElements.length > 0) {
		for (let i = 0; i < inputsElements.length; i++) {
			if (inputsElements[i].checked) {
				return inputsElements[i];
			}
		}
	}

	return null;
}

/**
 * @param {Element} inputElement
 */
export function setInputChecked(inputElement) {
	if (inputElement === null) {
		throw "Input element can not be null.";
	}

	// get all inputs of same name from the form
	let inputs = inputElement
		.closest("form")
		.querySelectorAll(
			"input[name=" + inputElement.getAttribute("name") + "]"
		);
	if (inputs === null) {
		return;
	}

	// every input set as not checked
	[...inputs].forEach((element) => {
		element.checked = false;
		element.removeAttribute("checked");
	});

	// finally set required element as checked
	inputElement.checked = true;
	inputElement.setAttribute("checked", "checked");
}

/**
 * @param {NodeListOf<Element>} inputRadioList
 * @param {String} inputRadioValue
 */
export function setInputRadioChecked(inputRadioList, inputRadioValue) {
	if (inputRadioList.length === 0) {
		throw "Input radio list must be non-empty.";
	}
	if (inputRadioValue === null) {
		throw "Input radio value must be set.";
	}

	// go through every input and check that meets the required value
	[...inputRadioList].forEach((element) => {
		if (element.value === inputRadioValue) {
			element.checked = true;
			element.setAttribute("checked", "checked");
		} else {
			element.checked = false;
			element.removeAttribute("checked");
		}
	});
}

export function insertHtmlAfter(newNode, referenceNode) {
	referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export function delay(time) {
	return new Promise(resolve => setTimeout(resolve, time));
}

export class Lazyload {
    constructor() {
        this.$images = null;
        this.imageCount = 0;
        this.observer = null;
    }

    init($imageSelector) {
        // re-init -> disconnect previous observer and create new one
        if (this.observer !== null) {
            this.observer.disconnect();
        }
        this.$images = $imageSelector;
        this.initObserver();
    }

    initObserver() {
        let _this = this;

        if (!('IntersectionObserver' in window)) {
            this.$images.forEach(function (imageElement) {
                _this.preloadImage(imageElement);
            });
        } else {
            this.imageCount = this.$images.length;
            this.observer = new IntersectionObserver(
                function (entries) {
                    // Disconnect if we've already loaded all of the images
                    if (_this.imageCount === 0) {
                        _this.observer.disconnect();
                    }

                    // Loop through the entries
                    entries.forEach(function (entryElement) {
                        if (entryElement.intersectionRatio > 0) {
                            _this.imageCount--;
                            _this.observer.unobserve(entryElement.target);
                            _this.preloadImage(entryElement.target);
                        }
                    });
                },
                {
                    rootMargin: '200px 0px', threshold: 0.01
                }
            );

            this.$images.forEach(function (imageElement) {
                if (imageElement.classList.contains('-js-lazyImageLoaded')) {
                    return;
                }
                _this.observer.observe(imageElement);
            });
        }
    }

    preloadImage(image) {
        let src = image.getAttribute('data-src');
        if (!src) {
            return;
        }

        this.applyImage(image, src);
    }

    applyImage(img, src) {
        // Prevent this from being lazy loaded a second time.
        img.classList.add('-js-lazyImageLoaded');

        if (img.nodeName === 'IMG') {
            img.src = src;
        } else if (img.nodeName === 'SOURCE') {
            img.srcset = src;
        }

        img.classList.add('-js-fadeIn');
    }
}
export class Countdown {
    init() {
        let _this = this;

        if (document.querySelector('.js-countdown') !== null) {
            _this.changeValues();
            setInterval(function () {
                _this.changeValues();
            }, 1000);
        }
    }

    changeValues() {
        let now = new Date().getTime();

        [...document.querySelectorAll('.js-countdown')].forEach(elm => {
            let date = elm.getAttribute('data-countdown').replace(' ', 'T');
            let until = new Date(date).getTime();
            let distance = until - now;

            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            elm.innerHTML = (days + "d " + hours + "h " + minutes + "m " + seconds + "s");
        });
    }
}

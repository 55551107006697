export class Dropdown {
	constructor() {
		this.$dropdownSelectors = [];
		// Dropdowns which are affected by document clicks
		this.$dropdownSelectorsCloseOnDocument = [];
	}

	init() {
		this.$dropdownSelectors = [];
		this.$dropdownSelectorsCloseOnDocument = [];

		// Mobile - header menu item
		this.addDropdown(
			document.querySelectorAll(".js-mobile-nav-menu:not(.-js-initiated)")
		);

		// Mobile - header menu item
		// this.addDropdown($('.js-nav-button'));

		// Configurator - share dropdown
		this.addDropdown(
			document.querySelectorAll(".js-share-button:not(.-js-initiated)")
		);

		// Mobile - search
		this.addDropdown(
			document.querySelectorAll(".js-search-button:not(.-js-initiated)")
		);

		// Mobile - info page sidebar
		this.addDropdown(
			document.querySelectorAll(
				".js-side-menu-button:not(.-js-initiated)"
			)
		);

		// FAQ page
		this.addDropdown(
			document.querySelectorAll(".js-faq-button:not(.-js-initiated)")
		);

		// Language select in footer
		this.addDropdown(
			document.querySelectorAll(
				".js-footer-lang-button:not(.-js-initiated)"
			),
			true
		);

		// Order by in filter
		this.addDropdown(
			document.querySelectorAll(".js-sort-button:not(.-js-initiated)"),
			true
		);

		// Order by in filter
		this.addDropdown(
			document.querySelectorAll(
				".js-stone-size-button:not(.-js-initiated)"
			),
			true
		);

		this.addListeners();
	}

	/**
	 * Re-initiate dropdowns
	 */
	reInit() {
		// This module can handle and recognize what to re-init.
		// -> for this module it is all same as in init function
		this.init();
	}

	addDropdown(selector, closeOnDocument) {
		this.$dropdownSelectors.push(selector);

		if (closeOnDocument) {
			this.$dropdownSelectorsCloseOnDocument.push(selector);
		}
	}

	addListeners() {
		let _this = this;

		[..._this.$dropdownSelectors].forEach((dropdownsList) => {
			if (dropdownsList.length > 0) {
				[...dropdownsList].forEach(function (dropdownElement) {
					// skip if already initiated
					if (dropdownElement.classList.contains("-js-initiated")) {
						return;
					}

					// add listener
					dropdownElement.addEventListener("click", function (e) {
						e.stopPropagation();
						let expanded =
							dropdownElement.getAttribute("aria-expanded") ===
							"true";
						if (expanded) {
							_this.closeDropdown(dropdownElement);
						} else {
							_this.openDropdown(dropdownElement);
						}
					});

					// mark it as initiated
					dropdownElement.classList.add("-js-initiated");
				});
			}
		});

		// Close all when click outside of listened elements

		// @todo this is connected with jquery sliding effect - not need now (not even functional)
		document.addEventListener("click", function () {
			[..._this.$dropdownSelectorsCloseOnDocument].forEach(
				(dropdownsList) => {
					if (dropdownsList.length > 0) {
						[...dropdownsList].forEach((dropdownElement) => {
							_this.closeDropdown(dropdownElement);
						});
					}
				}
			);
		});
	}

	openDropdown($element) {
		[...this.$dropdownSelectorsCloseOnDocument].forEach((dropdownsList) => {
			if (dropdownsList.length > 0) {
				[...dropdownsList].forEach((dropdownElement) => {
					this.closeDropdown(dropdownElement);
				});
			}
		});

		$element.setAttribute("aria-expanded", true);
		if ($element.nextElementSibling !== null) {
			$element.nextElementSibling.removeAttribute("hidden");
		}
		if ($element.firstElementChild !== null) {
			$element.firstElementChild.classList.toggle("active", true);
		}
	}

	closeDropdown($element) {
		$element.removeAttribute("aria-expanded");
		if ($element.nextElementSibling !== null) {
			$element.nextElementSibling.setAttribute("hidden", "hidden");
		}
		if ($element.firstElementChild !== null) {
			$element.firstElementChild.classList.toggle("active", false);
		}
	}
}

/**
 * General functionality for showing/hiding loaders.
 */
export class Loader {
    createLoaderHtml() {
        let loaderTemplate = document.querySelector('.c-loader-template').cloneNode(true);
        loaderTemplate.classList.remove('c-loader-template', 'hidden');
        loaderTemplate.classList.add('c-loader');

        return loaderTemplate.outerHTML;
    }

    /**
     * @param {Element} $element
     */
    showLoader($element) {
        if ($element === null) {
            return;
        }

        // get element actual height
        let elementHeight = $element.offsetHeight;

        let $actualLoader = $element.querySelector('.c-loader');
        if ($actualLoader === null) {
            $element.insertAdjacentHTML('afterbegin', this.createLoaderHtml());
        }

        $actualLoader = $element.querySelector('.c-loader');
        let loaderCalled = parseInt($actualLoader.getAttribute('data-counter'));
        $actualLoader.setAttribute('data-counter', (loaderCalled + 1).toString())

        // some element might have specific CSS rules that are not OK for auto calc of height
        // -> add height directly
        $actualLoader.style.height = elementHeight + "px";
    }

    /**
     * @param {Element} $element
     */
    hideLoader($element) {
        if ($element === null) {
            return;
        }

        let $actualLoader = $element.querySelector('.c-loader');

        // no loader -> nothing to hide
        if ($actualLoader === null) {
            return;
        }

        // calculate loader counter
        let loaderCalled = parseInt($actualLoader.getAttribute('data-counter'));
        // if this is the last required show -> remove loader
        if (loaderCalled === 1) {
            $actualLoader.remove();
        }

        // otherwise -> update counter
        $actualLoader.setAttribute('data-counter', (loaderCalled - 1).toString())
    }
}
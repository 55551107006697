import {
	elementHide,
	elementShow,
} from './features';
import intlTelInput from 'intl-tel-input';

export class PhoneNumberValidation {
	init() {
		let _this = this;

		_this.inputs = document.querySelectorAll('.js-phone-validation');
		if (_this.inputs.length === 0) {
			return;
		}
		if (_this.inputs.length > 1) {
			console.error('Only one phone input can be active in the DOM!');
		}
		_this.input = _this.inputs[0];
		_this.formSubmitButton = _this.input.closest('form').querySelector('button[type=submit]');
		_this.errorMsg = document.querySelector("#phone-validation-error-msg");
		// Don't need this right now
		// _this.validMsg = document.querySelector("#phone-validation-valid-msg");
		_this.inputTargetName = _this.input.getAttribute('data-target-input-name');

		this.iti = intlTelInput(this.input, {
			initialCountry: window.Danfil.country,
			// do not set `separateDialCode` to `true` - it is setting buggy padding-left which makes input value disappear
			separateDialCode: false,
			formatOnDisplay: true,
			autoFormat: true,
			numberType: "MOBILE",
			nationalMode: true,
			// This will create hidden input that is sent along with form inputs.
			// It will add full value of the phone number, means including dial code.
			hiddenInput: _this.inputTargetName,
			onlyCountries: [
				'AT',
				'BE',
				'BG',
				'BY',
				'CH',
				'DK',
				'CZ',
				'DE',
				'FR',
				'GB',
				'NL',
				'SK',
				'ES',
				'RO',
				'UK',
				'PT',
				'DN',
				'NO',
				'HR',
				'LT',
				'EE',
				'FI',
				'SI',
				'UA',
				'GL',
				'GR',
				'HU',
				'IS',
				'LI',
				'LU',
				'LV',
				'MC',
				'PL',
				'SE',
			],
			enablePlaceholder: true,
			// numberFormat: '+420 111 222 333',
			phoneValidation: true,
			// @todo this should NOT be downloaded from CDN, but generated on our side!
			utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@18.2.1/build/js/utils.js',
		});

		this.addListeners();
	}

	addListeners() {
		let _this = this;
		_this.reset();

		// on click button: validate
		_this.formSubmitButton.addEventListener('click', (ev) => {
			_this.check(ev);
		});

		// on keyup / change flag: reset
		_this.input.addEventListener('change', (ev) => {
			_this.reset();
			_this.check(ev);
		});

		// on keydown validate the newly added character
		_this.input.addEventListener('keydown', (ev) => {
			let isValid = _this.isValid(this, ev);
			if (isValid === false) {
				ev.preventDefault();
			}
		});
	}

	isValid(el, event) {
		let charC = (event.which) ? event.which : event.keyCode;

		// whitelist of allowed keys (shift, control, ...)
		const charsWhiteList = [
			// backspace
			8,
			// tab
			9,
			// enter
			13,
			// shift
			16,
			// ctrl
			17,
			// alt
			18,
			// pause/break
			19,
			// caps lock
			20,
			// escape
			27,
			// page up
			33,
			// page down
			34,
			// end
			35,
			// home
			36,
			// left arrow
			37,
			// up arrow
			38,
			// right arrow
			39,
			// down arrow
			40,
			// insert
			45,
			// delete
			46
		];
		if (charsWhiteList.includes(charC)) {
			return true;
		}

		let myRe = /([0-9]|\+|\-|\s)/g;
		let myArray = myRe.exec(event.key);

		return myArray !== null;
	}

	reset() {
		this.input.classList.remove("error");
		this.errorMsg.innerHTML = "";
		elementHide(this.errorMsg);
		// Don't need this right now
		// elementHide(this.validMsg);
	};

	check(ev) {
		this.reset();
		if (this.input.value.trim()) {
			if (this.iti.getValidationError() === 0) {
				// Don't need this right now
				// elementHide(this.validMsg);
				this.input.classList.remove("error");
				this.input.classList.add("valid");
			} else {
				ev.preventDefault();
				elementShow(this.input);
				let validationError = this.iti.getValidationError();
				if (validationError === -99) {
					validationError = 4;
				}
				this.errorMsg.innerHTML = window.Danfil.phoneValidationErrorsMap[validationError];
				elementShow(this.errorMsg);
				this.input.classList.remove("valid");
				this.input.classList.add("error");
			}
		}
	}
}

import axios from 'axios';
import Swal from 'sweetalert2';
// modules
import {CookieBar} from './module/cookie-bar';
import {Countdown} from './module/countdown';
import {DataLayer} from './module/data-layer';
import {Dropdown} from './module/dropdown';
import {HeaderBar} from './module/header-bar';
import {HeaderCart} from './module/header-cart';
import {HeaderMobileMenu} from './module/header-mobile-menu';
import {Lazyload} from './module/lazyload';
import {PhoneNumberValidation} from "./module/phone-number-validation";
import {ProductListCommon} from './module/product-list/product-list-common';

// init Cookie bar
const cookieBar = new CookieBar();
cookieBar.init();

// init lazy-loading images
const lazyLoadInstance = new Lazyload();
lazyLoadInstance.init(document.querySelectorAll('.lazyloading[data-src]:not(.-js-lazyImageLoaded)'));

// data layer
const dataLayer = new DataLayer();
dataLayer.init();

// init dropdowns
const dropdown = new Dropdown();
dropdown.init();

// init countdown
const countdown = new Countdown();
countdown.init();

// init header bar
const headerBar = new HeaderBar();
headerBar.init();

// init header mobile menu
const headerMobileMenu = new HeaderMobileMenu();
headerMobileMenu.init();

// init clickable products
const productListCommon = new ProductListCommon();
productListCommon.initClickableProducts();


// load header cart
// (the counting mechanism is not in every page but only in cart page -> loading by ajax -> bad practice -> needs refactor)
const headerCart = new HeaderCart();
window.addEventListener('DOMContentLoaded', () => {
	// needs DOMContentLoaded because of loader template
	headerCart.updateHeaderCart();
});

// init phone number validation
const phoneNumberValidation = new PhoneNumberValidation();
phoneNumberValidation.init();

// General popup links
let popupLinks = document.querySelectorAll('.js-popup');

[...popupLinks].forEach((popupLink) => {   

	if (popupLink !== null) {
	
		popupLink.addEventListener('click', (e) => {

			let popupHtml = document.querySelector(popupLink.getAttribute('href')).cloneNode(true);
			Swal.fire({
				// disable popup animations
				showClass: {
					popup: '',
					icon: ''
				},
				hideClass: {
					popup: ''
				},

				// content
				html: popupHtml.innerHTML,
				showCloseButton: true,
				showConfirmButton: false,
				inputAutoFocus: true,
				didRender: function () {
					lazyLoadInstance.init(document.querySelectorAll('.lazyloading[data-src]:not(.-js-lazyImageLoaded)'));
				}
			});
		});
	}

});


/*
	re-initiate for ajax loaded content
	how does it work:
		- body element has attribute `data-xhrlcm`
		- this attribute is changed every time the content is loaded by ajax
		  important - the change must be provided manually using AjaxContentObserver.updateObserverElement
		- scripts from method `mutationCallback` are triggered when the attribute is changed
		- the re-init function of each module must know how to work with multiple initializations
 */
const mutationCallback = (mutationsList) => {
	for (const mutation of mutationsList) {
		if (
			mutation.type !== "attributes" ||
			mutation.attributeName !== "data-xhrlcm"
		) {
			return
		}

		// re-init dropdowns
		dropdown.reInit();

		// re-init dataLayer
		dataLayer.reInit();
	}
}

const observer = new MutationObserver(mutationCallback)
observer.observe(document.querySelector('body'), { attributes: true })


// @todo delete when google sign-in will start working
let googleLoginButton = document.querySelector('.js-google-login');
/*if (googleLoginButton !== null) {
	googleLoginButton.addEventListener('click', function() {
		auth2.grantOfflineAccess().then(signInCallback);
	});
}*/
/*
window.onload = function () {
	google.accounts.id.initialize({
		client_id: "{$system.google_client_id}",
		callback: handleCredentialResponse
	});
	google.accounts.id.renderButton(
		document.getElementById("buttonDiv"),
		{ theme: "outline", size: "large" }  // customization attributes
	);
	google.accounts.id.prompt(); // also display the One Tap dialog
}
*/

function signInCallback(authResult) {
	if (authResult['code']) {
		// Hide the sign-in button now that the user is authorized, for example:
		document.querySelector('#signinButton')?.setAttribute('style', 'display: none');

		// Send the code to the server
		axios({
			method: 'post',
			url: window.Danfil.urlRoot + '?googlesignin',
			headers: {
				// Always include an `X-Requested-With` header in every AJAX request, to protect against CSRF attacks.
				'X-Requested-With': 'XMLHttpRequest',
				'Content-Type': 'application/octet-stream; charset=utf-8',
			},
			data:authResult['code']
		})
		.then(function (response) {
			if (response.data === "ok") {
				window.location.reload(false);
			} else {
				alert(response.data);
			}
			// Handle or verify the server response.
		}).catch(function () {
			console.error('Požadavek se nepodařilo odeslat.');
		});
	} else {
		// There was an error.
	}
}
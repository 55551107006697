import Cookies from 'js-cookie';

export class CookieBar {
    constructor() {
        // new
        this.cookieModal = null;
        this.cookieSettingsList = null;
        this.cookieSettingsButton = null;
        this.cookieConfirmButton = null;
        this.cookieButtons = [];
        this.setCookiesButtons = null;
    }

    init() {
        const that = this;

        this.cookieModal = document.querySelector('.js-cookie-modal');
        this.cookieSettingsList = document.querySelector('.js-cookie-settings');
        this.cookieSettingsButton = document.querySelector('.js-cookie-settings-button');
        this.cookieConfirmButton = document.querySelector('.js-cookie-confirm-button');
        this.cookieDeclineButton = document.querySelector('.js-cookie-decline-button');
        this.cookieCloseButton = document.querySelector('.js-cookie-close-button');
        this.cookieConfirmChoicesButton = document.querySelector('.js-cookie-confirm-choices');
        this.cookieButtons = [...document.querySelectorAll('.js-cookie-button')];
        this.setCookiesButtons = [...document.querySelectorAll('.js-set-cookies-button')];

        let numberOfSetCookies = 0;

        [...this.cookieButtons].forEach(cookieButton => {
            if (Cookies.get(cookieButton.dataset.cookie)) {
                numberOfSetCookies++;
            }

            if ('1' === Cookies.get(cookieButton.dataset.cookie)) {
                cookieButton.checked = true
            }
        });

        [...this.setCookiesButtons].forEach(setCookieButton => {
            setCookieButton.addEventListener('click', function (e) {
                e.preventDefault();
                that.openPopup();
            })
        }); 

        this.addListeners();

        // check if all cookies are set
        if (numberOfSetCookies !== 3) {
            that.openPopup();
        }
    }

    openPopup() {
        this.cookieModal.classList.remove('hidden');
        dataLayer.push({'event': 'cookie_bar_show'});
    }

    addListeners() {
        let _this = this;
        // click on settings button
        this.cookieSettingsButton.addEventListener('click', function () {
            _this.cookieSettingsButton.classList.add('hidden');
            _this.cookieConfirmButton.classList.add('hidden');
            _this.cookieDeclineButton.classList.add('hidden');

            _this.cookieSettingsList.classList.remove('hidden');
            _this.cookieConfirmChoicesButton.classList.remove('hidden');
        });

        // click on confirm button
        this.cookieConfirmButton.addEventListener('click', function () {
            _this.cookieModal.classList.add('hidden');
            _this.setCookies(true);

            dataLayer.push({'event': 'cookie_set'});
        });


        // click on decline button
        this.cookieDeclineButton.addEventListener('click', function () {
            _this.uncheckCookieButtons();
            _this.cookieModal.classList.add('hidden');
            _this.setCookies(false);

            dataLayer.push({'event': 'cookie_set'});
        });

        // click on close button
        this.cookieCloseButton.addEventListener('click', function () {
            _this.cookieModal.classList.add('hidden');
            _this.setCookies(false);

            dataLayer.push({'event': 'cookie_set'});
        });

        // click on confirm choices button
        this.cookieConfirmChoicesButton.addEventListener('click', function () {
            _this.cookieModal.classList.add('hidden');
            _this.setCookies();

            dataLayer.push({'event': 'cookie_set'});
        });
    }

    // set cookies
    setCookies(setAll) {
        // iterate all inputs, when is checked set cookie by data value
        this.cookieButtons.forEach(function (cookieButton) {
            if (true === setAll) {
                Cookies.set(cookieButton.dataset.cookie, 1, {expires: 365});
                cookieButton.checked = true;
            } else {
                Cookies.set(cookieButton.dataset.cookie, cookieButton.checked ? 1 : 0, {expires: 365});
            }
        });
    }

    // Uncheck all buttons
    uncheckCookieButtons() {
        // iterate all inputs
        this.cookieButtons.forEach(function (cookieButton) {
            // Exception for functional cookies 
            if (!cookieButton.disabled) {
                cookieButton.checked = false;
            }
        });
    }
}

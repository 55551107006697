// npm libs
import axios from 'axios';
// helpers
import {Errors} from '../../helpers/errors';
// modules
import {AjaxContentObserver} from '../ajax-content-observer';
import {Lazyload} from '../lazyload';

export class ProductListCommon {

	constructor() {
		this.errors = new Errors();
		this.ajaxContentObserver = new AjaxContentObserver();
	}

	initDirectAlways() {
		this.initClickableProducts();

		// handling button "show more products"
		let buttonLoadMoreProducts = document.querySelector('.js-load-more-products');
		if (buttonLoadMoreProducts !== null) {
			this.loadProductsButton(buttonLoadMoreProducts);
		}
	}

	/**
	 * @param {Element} element
	 */
	loadProductsButton(element) {
		if (element === null) {
			console.error(this.errors.errors.elementNotExist);
		}

		let _this = this;

		element.addEventListener('click', function () {
			element.classList.add('is-loading');
			element.innerHTML = "Načítám";

			// exist element actual link in paginator?
			let urlElement = document.querySelector('.collection-pagelinks__paginator-item--actual');
			if (urlElement === null) {
				return
			}

			// exist next page?
			if (urlElement.nextElementSibling.children[0] === undefined) {
				return;
			}

			// get url from detected element
			let url = urlElement.nextElementSibling.children[0].getAttribute('href');
			let ajaxUrl = url + '?ajax_content=1';

			// load next page
			// these products append to the list (so it is NOT snippet)
			_this.fetchAndRenderMoreProducts(ajaxUrl);

			// push the url to the history, so browser back link will work properly
			if (history.pushState) {
				history.pushState(null, null, url);
			}
		});
	}

	/**
	 * @param {String} ajaxUrl
	 */
	fetchAndRenderMoreProducts(ajaxUrl) {
		let _this = this;
		axios.get(ajaxUrl)
			.then(function (response) {
				// create DOM node from received plain text
				let html = (new DOMParser()).parseFromString(response.data, 'text/html')

				let pageLinks = document.querySelector(".collection-pagelinks");
				let ajaxProducts = html.querySelectorAll('.products .js-product-clickable');
				let ajaxPaginator = Array.from(html.querySelector('.collection-pagelinks').childNodes);

				// propagate fetched items to the list
				let productsNode = document.querySelector('.products');
				for (let i = 0; i < ajaxProducts.length; i++) {
					productsNode.appendChild(ajaxProducts[i])
				}

				// propagate new paginator
				pageLinks.innerHTML = '';
				for (let i = 0; i < ajaxPaginator.length; i++) {
					pageLinks.appendChild(ajaxPaginator[i]);
				}

				const lazyLoadInstance = new Lazyload();
				lazyLoadInstance.init(document.querySelectorAll('.lazyloading[data-src]:not(.-js-lazyImageLoaded)'));

				const productListCommonInstance = new ProductListCommon();
				productListCommonInstance.initClickableProducts();

				let ajaxButton = pageLinks.querySelector('.js-load-more-products');
				if (ajaxButton !== null) {
					_this.loadProductsButton(ajaxButton);
				}

				// for newly inserted HTML must be re-init listeners
				_this.ajaxContentObserver.updateObserverElement();
			})
			.catch(function (error) {
				// handle error
				console.log(error);
			});
	}

	initClickableProducts() {
		// Click of product div behave as click on link
		this.addClickBehaviour(
			document.getElementsByClassName("js-product-clickable"),
			".c-product-card__name a",
			"_self"
		);
		this.addClickBehaviour(
			document.getElementsByClassName("c-collection-cover-subcat__item"),
			".c-collection-cover-subcat__item-name a",
			"_self"
		);
	}

	/**
	 * @param {NodeList<Element>} elementsList
	 * @param {String} linkSelector
	 * @param {String} windowName
	 */
	addClickBehaviour(elementsList, linkSelector, windowName) {
		if (elementsList && elementsList.length !== 0) {
			for (let i = 0; i < elementsList.length; i++) {
				let $element = elementsList[i];

				let $linkElement = $element.querySelector(linkSelector);
				if ($linkElement === null || $linkElement === undefined) {
					continue;
				}
				let linkUrl = $linkElement.getAttribute("href");

				// timer
				let down = 0;

				/*
                    Event listeners for "clickable" devices.
                    (e.g. desktop)
                    We want to provide to select the text, so instead the standard click event
                    are used mousedown & mouseup events.
                 */
				$element.addEventListener(
					"mousedown",
					function (e) {
						e.preventDefault();
						// mark date when this event started - used in mouseup event
						down = Date.now();
					},
					false
				);

				$element.addEventListener(
					"mouseup",
					function (e) {
						e.preventDefault();
						// if the time between two events are short we can consider it
						// is click -> redirect
						// otherwise it is some selection -> do nothing!
						if (Date.now() - down < 200) {
							window.open(linkUrl, windowName);
						}
					},
					false
				);

				/*
                    Event listeners for "touchable" devices. (e.g. mobile or tablet)
                    This is tricky because there is not `touch` event like `click`.
                    We need to do this:
                        1) touchstart - touch is initiated by user
                            !important! this can be any touch like scrolling by finger
                        2) scroll - check if user touch the screen because he wants to scroll
                        3) touchmove - same as scroll, but scrolling is done with finger on the screen
                        4) touchend - finally check the end of touching the screen
                 */
				$element.addEventListener(
					"touchstart",
					function (e) {
						// mark date when this event started - used in mouseup event
						down = Date.now();
					},
					false
				);

				window.addEventListener(
					"scroll",
					function (e) {
						// When the scrolling event is going, we want to disable the touchend,
						// This is simple hack that we put the timer back 1 second.
						down = Date.now() - 1000;
					},
					false
				);

				window.addEventListener(
					"touchmove",
					function (e) {
						// When the scrolling event is going, we want to disable the touchend,
						// This is simple hack that we put the timer back 1 second.
						down = Date.now() - 1000;
					},
					false
				);

				$element.addEventListener(
					"touchend",
					function (e) {
						// The time between two events is short we can consider it as is click -> redirect.
						// Otherwise, it is some selection -> do nothing!
						if (Date.now() - down < 200) {
							window.open(linkUrl, windowName);
						}
					},
					false
				);
			}
		}
	}
}

// npm libs
import axios from 'axios';
// helpers
import {Currency} from '../helpers/currency';
import {Errors} from '../helpers/errors'
// modules
import {Loader} from "./loader";

export class HeaderCart {
    constructor() {
        // init variables
        // located in `public/danfil.cz/config/ajax.php`
        this.snippetName = 'header_cart';
        this.cartBaseUrl = location.origin + window.Danfil.urlRoot + window.Danfil.cartSlug;
        // init helpers
        this.currency = new Currency();
        this.errors = new Errors();
        // init modules
        this.loader = new Loader();
    }

    updateHeaderCart() {
        let _this = this;
        let $headerCartContent = document.querySelector('.c-header__cart-box-item--cart-content');

        // on some pages like payment, deliver, order finish, ... is not the classic main menu
        // -> no need to update the content
        if ($headerCartContent === null) {
            console.warn('Header cart not found -> skipping ajax request.');
            return;
        }

        // do not apply `ajax_content=1` (with that is not returned required HTML part)
        let ajaxUrl = new URL(_this.cartBaseUrl);
        ajaxUrl.searchParams.append('snippet', _this.snippetName);

        // show loader in the product basics
        _this.loader.showLoader($headerCartContent);

        axios({
            method: 'get',
            url: ajaxUrl.toString(),
            headers: {
                // Always include an `X-Requested-With` header in every AJAX request, to protect against CSRF attacks.
                'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/octet-stream; charset=utf-8'
            },
        })
            .then(function (response) {
                // fetch snippet data
                let snippetData = response.data[_this.snippetName];
                if (typeof snippetData === 'undefined') {
                    throw _this.errors.errors.snippetContentError;
                }

                // extract required data and insert them to DOM
                let ajaxSnippetHtml = (new DOMParser()).parseFromString(snippetData.snippet, 'text/html');

                // container in the modal where product will be updated
                let $newContent = ajaxSnippetHtml.querySelector('.c-header__cart-box-item--cart-content');
                if ($newContent !== null) {
                    $headerCartContent.innerHTML = $newContent.innerHTML;
                } else {
                    console.error('Header cart box update failed!');
                }
            }).finally(function () {
                _this.loader.hideLoader($headerCartContent);
            }).catch(function (error) {
                // HTTP response code 499 is OK from our side.
                // Probably caused by GoogleBot (or some other crawler) who is firing too much event on the server.
                if (error.response?.status === 499) {
                    console.error('Client has closed the connection abruptly before the server could respond to the request.', error.response.data);
                    return undefined;
                }

                // something else happened -> treat that as standard exception
                throw error;
            });
    }
}
